import * as React from 'react'
import { memo } from 'react'

const SvgPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 31.943 32"
  >
    <path d="M30.941 23.461v4.516a3.009 3.009 0 0 1-3 3.023c-.095 0-.19 0-.283-.011a29.792 29.792 0 0 1-12.992-4.622 29.343 29.343 0 0 1-9.031-9.034A29.783 29.783 0 0 1 1.013 4.281a3.01 3.01 0 0 1 2.728-3.269A2.637 2.637 0 0 1 4.009 1h4.516a3.012 3.012 0 0 1 3.011 2.589 19.311 19.311 0 0 0 1.049 4.231 3.011 3.011 0 0 1-.677 3.176l-1.912 1.912a24.087 24.087 0 0 0 9.033 9.033l1.916-1.908a3.011 3.011 0 0 1 3.176-.677 19.311 19.311 0 0 0 4.231 1.053 3.01 3.01 0 0 1 2.589 3.052Z" />
  </svg>
)

const Memo = memo(SvgPhone)
export default Memo
