import * as React from 'react'
import { memo } from 'react'

const SvgYoutube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28.264 20"
  >
    <path d="M22.394 0H5.87A5.87 5.87 0 0 0 0 5.87v8.26A5.87 5.87 0 0 0 5.87 20h16.524a5.87 5.87 0 0 0 5.87-5.87V5.87A5.87 5.87 0 0 0 22.394 0Zm-3.97 10.4L10.7 14.088a.31.31 0 0 1-.444-.28V6.207a.31.31 0 0 1 .45-.277l7.729 3.916a.31.31 0 0 1-.011.556Z" />
  </svg>
)

const Memo = memo(SvgYoutube)
export default Memo
