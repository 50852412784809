import * as React from 'react'
import { memo } from 'react'

const SvgMove = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 31.5 31.5"
  >
    <path d="m5.25 11.25-4.5 4.5 4.5 4.5M11.25 5.25l4.5-4.5 4.5 4.5M20.25 26.249l-4.5 4.5-4.5-4.5M26.249 11.25l4.5 4.5-4.5 4.5M.75 15.75h30M15.75.75v30" />
  </svg>
)

const Memo = memo(SvgMove)
export default Memo
