import * as React from 'react'
import { memo } from 'react'

const SvgUsers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 38.167 31.5"
  >
    <g transform="translate(-.583 -3.25)">
      <path d="M28 34v-3.334A6.667 6.667 0 0 0 21.333 24H8a6.667 6.667 0 0 0-6.668 6.666V34" />
      <circle cx={6.667} cy={6.667} r={6.667} transform="translate(8 4)" />
      <path d="M38.001 34v-3.334a6.668 6.668 0 0 0-5-6.45M26.333 4.216a6.667 6.667 0 0 1 0 12.918" />
    </g>
  </svg>
)

const Memo = memo(SvgUsers)
export default Memo
