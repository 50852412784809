import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  label: string
  file?: string
  target?: string
  URL?: string
  onClick?: () => void
  className?: string
  noActions?: boolean
  rel?: string
  variant?: Variant
}

export const Button = memo(function Button({
  label = 'Button',
  file,
  target,
  URL,
  onClick,
  className,
  noActions,
  rel,
  variant = 'default',
}: Props) {
  const buttonClassName = `${className ? className : ''}`
  const buttonDOM = <Label>{label}</Label>
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <>
      {file ? (
        <Anchor
          className={buttonClassName}
          href={file}
          target="_blank"
          rel={rel}
          variant={variant}
        >
          {buttonDOM}
        </Anchor>
      ) : (
        <>
          {!externalURL ? (
            onClick != undefined ? (
              <Anchor
                className={buttonClassName}
                onClick={onClick}
                variant={variant}
              >
                {buttonDOM}
              </Anchor>
            ) : noActions ? (
              <Static className={buttonClassName} variant={variant}>
                {buttonDOM}
              </Static>
            ) : (
              <ButtonItem
                to={URL ? URL : '#'}
                className={buttonClassName}
                variant={variant}
              >
                {buttonDOM}
              </ButtonItem>
            )
          ) : (
            <Anchor
              className={buttonClassName}
              href={URL}
              rel="noopener"
              target={target}
              variant={variant}
            >
              {buttonDOM}
            </Anchor>
          )}
        </>
      )}
    </>
  )
})

const Style = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  background: ${theme.colors.variants.primaryDark2};
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.125rem;
  padding: 0 2.25rem;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  &:hover {
    background: ${theme.colors.variants.primaryDark3};
  }
`

const OutlineStyle = css`
  background: none;
  border: 0.125rem solid ${theme.colors.variants.neutralLight2};
  color: ${theme.colors.variants.primaryDark2};
  &:hover {
    background: ${theme.colors.variants.neutralLight2};
  }
`

const DarkStyle = css`
  background: ${theme.colors.variants.neutralLight2};
  color: ${theme.colors.variants.primaryDark2};
  &:hover {
    background: ${theme.colors.variants.neutralLight1};
    color: ${theme.colors.variants.neutralLight4};
  }
`

const InvertStyle = css`
  background: none;
  border: 0.125rem solid ${theme.colors.variants.neutralLight4};
  color: ${theme.colors.variants.neutralLight4};
  &:hover {
    border-color: transparent;
    background: ${theme.colors.variants.neutralLight2};
    color: ${theme.colors.variants.primaryDark2};
  }
`

const SimpleStyle = css`
  height: auto;
  background: none;
  border-top: 0.125rem solid ${rgba(theme.colors.variants.primaryDark2, 0.2)};
  border-bottom: 0.125rem solid ${rgba(theme.colors.variants.primaryDark2, 0.2)};
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.0813rem;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  &:hover {
    background: none;
    &:before {
      right: 50%;
    }
    &:after {
      left: 50%;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${theme.colors.variants.primaryDark2};
    top: -0.125rem;
    bottom: -0.125rem;
    transition: 0.3s ease-in-out;
  }
  &:before {
    right: 100%;
    left: 0.0625rem;
  }
  &:after {
    right: 0.0625rem;
    left: 100%;
  }
  span {
    background: ${theme.colors.variants.neutralLight4};
    padding: 0.75rem 0;
    position: relative;
    z-index: 2;
  }
`

const Anchor = styled.a<ContainerProps>`
  ${Style}

  ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'dark':
        return DarkStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const Static = styled.div<ContainerProps>`
  ${Style}

  ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'dark':
        return DarkStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const ButtonItem = styled(Link)<ContainerProps>`
  ${Style}

  ${({ variant }) => {
    switch (variant) {
      case 'outline':
        return OutlineStyle
      case 'invert':
        return InvertStyle
      case 'dark':
        return DarkStyle
      case 'simple':
        return SimpleStyle
    }
  }}
`

const Label = styled.span``

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'outline' | 'invert' | 'dark' | 'simple'
