import * as React from 'react'
import { memo } from 'react'

const SvgWave = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 1076 115.621"
  >
    <path d="m1046.503 50.009-31.009-17.241-38.11-8.584-38.14-5.3-32.872-3.4-37.932 1.74-48.44 12.1-45.032 13.74-34.619 5.189-41.425 1.758-31.244-3.4-49.975-6.885-39.977-13.9-41.541-13.741L503.573 0h-52.18L404.8 5.082l-31.126 10.4-24.1 5.2-31.232 6.84-24.219 7.047-43.185 1.7-43.288-3.5-29.47-10.386-20.714-5.16-34.738-5.138-25.859-5.3-22.473-3.5-22.353 1.8-25.962 5.26L3.608 20.686 0 41.409l3.608 19 18.848 8.643h19.292l18.849-5.143 29.484-3.5h24.1l31.129 8.643 25.962 5.2 22.472 6.781 24.32 5.363 31.232 3.383 34.513-5.141 22.355-3.6 31.232-10.242 20.713-5.242 24.336-5.141 24-3.561 29.47-3.446 41.646 3.446 43.185 10.445 38.023 6.958 36.273 13.888 39.767 8.539 34.619 6.841 31.125 3.561h39.8l43.286-10.4 32.875-12.041 62.224-17.345 22.369-3.383h31.334l24.115 3.383 26.067 8.643 15.354 6.958 13.907 10.338 12.163 15.487 19.084 6.9 15.565-12.1 15.531-15.38 1.774-17.348Z" />
  </svg>
)

const Memo = memo(SvgWave)
export default Memo
