import * as React from 'react'
import { memo } from 'react'
const SvgWhatsapp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 19.909 20"
  >
    <path d="M17.009 2.907a9.914 9.914 0 0 0-15.6 11.957L0 20l5.256-1.38a9.92 9.92 0 0 0 4.738 1.209 9.915 9.915 0 0 0 7.015-16.922ZM10 18.153a8.238 8.238 0 0 1-4.2-1.147l-.3-.178-3.121.817.833-3.04-.194-.311A8.239 8.239 0 1 1 10 18.153Zm4.517-6.17c-.246-.123-1.465-.723-1.692-.8s-.392-.123-.557.123-.638.807-.784.972-.288.185-.535.062a6.7 6.7 0 0 1-1.996-1.24 7.414 7.414 0 0 1-1.377-1.714c-.146-.25-.016-.382.107-.506s.25-.288.373-.434a1.724 1.724 0 0 0 .25-.412.462.462 0 0 0-.023-.434c-.062-.123-.557-1.345-.765-1.841s-.405-.415-.557-.424-.308-.006-.473-.006a.906.906 0 0 0-.661.311 2.771 2.771 0 0 0-.865 2.064 4.831 4.831 0 0 0 1.011 2.563 11.062 11.062 0 0 0 4.227 3.741 13.748 13.748 0 0 0 1.41.522 3.445 3.445 0 0 0 1.562.1 2.551 2.551 0 0 0 1.672-1.18 2.082 2.082 0 0 0 .143-1.176c-.058-.103-.224-.164-.473-.291Z" />
  </svg>
)
const Memo = memo(SvgWhatsapp)
export default Memo
