import * as React from 'react'
import { memo } from 'react'

const SvgChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12.414 22.829"
  >
    <path d="m11 21.414-10-10 10-10" />
  </svg>
)

const Memo = memo(SvgChevronLeft)
export default Memo
