import * as React from 'react'
import { memo } from 'react'

const SvgPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 26.545 32"
  >
    <g transform="translate(-3 -.333)">
      <path d="M28.545 13.605c0 9.546-12.272 17.728-12.272 17.728S4 23.151 4 13.605a12.272 12.272 0 1 1 24.545 0Z" />
      <circle cx={2.5} cy={2.5} r={2.5} transform="translate(14.078 11.115)" />
    </g>
  </svg>
)

const Memo = memo(SvgPin)
export default Memo
