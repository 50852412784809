import * as React from 'react'
import { memo } from 'react'

const SvgPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16.972 16.972"
  >
    <path d="M8.948 8.947h7.37a.462.462 0 1 0 0-.923H8.947V.653a.462.462 0 0 0-.923 0l.001 7.37-7.371.001a.462.462 0 0 0 0 .924l7.37-.001v7.37a.462.462 0 0 0 .924.002v-2.016l.002-5.355Z" />
  </svg>
)

const Memo = memo(SvgPlus)
export default Memo
