import * as React from 'react'
import { memo } from 'react'

const SvgArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10.828 6.815"
  >
    <path d="M1.414 1.414c1.007 1 3.955 3.987 3.955 3.987l4.045-3.987" />
  </svg>
)

const Memo = memo(SvgArrowDown)
export default Memo
