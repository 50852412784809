import * as React from 'react'
import { memo } from 'react'

const SvgTag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32.527 32.272"
  >
    <path d="M30.361 19.021 19.033 30.345a3.158 3.158 0 0 1-4.467 0L1 16.794V1h15.794l13.567 13.567a3.159 3.159 0 0 1 0 4.454ZM8.897 8.897h.016" />
  </svg>
)

const Memo = memo(SvgTag)
export default Memo
