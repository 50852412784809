import * as React from 'react'
import { memo } from 'react'

const SvgChevronsDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17.721 16.414"
  >
    <path d="m16.307 7.968-7.446 7.446-7.447-7.446" />
    <path d="M16.307 1.414 8.861 8.86 1.414 1.414" />
  </svg>
)

const Memo = memo(SvgChevronsDown)
export default Memo
