import * as React from 'react'
import { memo } from 'react'

const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32.869 26.067"
  >
    <path d="M4.401 1h24.067a3.017 3.017 0 0 1 3.008 3.009v18.05a3.017 3.017 0 0 1-3.008 3.008H4.401a3.017 3.017 0 0 1-3.008-3.009V4.009A3.017 3.017 0 0 1 4.401 1Z" />
    <path d="M31.476 4.009 16.434 14.538 1.393 4.009" />
  </svg>
)

const Memo = memo(SvgEmail)
export default Memo
