import * as React from 'react'
import { memo } from 'react'

const SvgChevronsUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17.721 16.414"
  >
    <path d="M16.307 8.446 8.86 1 1.414 8.446" />
    <path d="M16.307 15 8.86 7.554 1.414 15" />
  </svg>
)

const Memo = memo(SvgChevronsUp)
export default Memo
